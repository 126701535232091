import { ApolloClient } from 'apollo-client';
import {
    InMemoryCache,
    IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';

// TODO: Add proper retry logic
const NON_AUTH_OPERATIONS = [
    'obtainKrakenToken',
    'createQuote',
    'GetQuote',
    'CreateAcquisitionQuoteRequest',
    'energyProducts',
    'getBankAccountDetails',
    'getEarliestDirectDebitPaymentDate',
    'getQuoteForReviewPage',
    'getOccupierAccount',
];

// This magically re-runs when we log in so we can actually set the Authentication header
// after storing in localStorage.
// https://www.apollographql.com/docs/react/recipes/authentication#Header
// https://www.apollographql.com/docs/link/links/context
const createAuthLink = () =>
    setContext((operation, { headers }) => {
        if (NON_AUTH_OPERATIONS.includes(operation.operationName)) {
            return { headers };
        }
        // return the headers to the context so httpLink can read them
        const newHeaders = { ...headers };

        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('token');
        const masqueradeToken = localStorage.getItem('masqueradeToken');
        const scopedKrakenToken = localStorage.getItem('scopedKrakenToken');

        // return the headers to the context so httpLink can read them
        const authHeader = masqueradeToken || token || scopedKrakenToken;

        if (masqueradeToken || token) {
            /**
             * if a masquerade or access token is used for auth,
             * we can remove the scoped token as they have a wider auth scope
             */
            localStorage.removeItem('scopedKrakenToken');
        }

        if (authHeader) {
            newHeaders['Authorization'] = authHeader;
        }

        return { headers: newHeaders };
    });

const initialiseClient = ({
    uri,
    introspectionQueryResultData,
    data = undefined,
    headers,
}) => {
    // eslint-disable-next-line
    console.log('USING OLD APOLLO CLIENT');
    const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData,
    });
    const cache = new InMemoryCache({ fragmentMatcher });
    const httpLink = createHttpLink({ uri, headers });
    const authLink = createAuthLink();

    const client = new ApolloClient({
        cache,
        link: authLink.concat(httpLink),
        resolvers: {},
    });

    // set up the initial state
    if (data) {
        cache.writeData({ data });
    }

    return client;
};

export default initialiseClient;
